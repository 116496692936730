import React, { useEffect, useState } from 'react'
import ReactPageScroller from 'react-page-scroller';

const About = () => {
  const [animate, setAnimate] = useState(true);

  useEffect(() => {
  setTimeout(() => {
    setAnimate(false)
    }, 1000);
}, [])

  return (
    <>

<div className={`${animate ? "scale-in-hor-right" : "scale-out-hor-left"}`}></div>
    
    {!animate && <div className="container fade-in about">
    

    <div className="d-none d-md-block">
    {/* <ReactPageScroller
          pageOnChange={(e) => handlePageChange(e)}
          onBeforePageScroll={(e) => handleBeforePageChange(e)}
          containerWidth={"100%"}
          containerHeight= {"100%"}
          transitionTimingFunction = {"ease"}
          animationTimerBuffer = {10}
        > */}

      <section className='h-100 section1 pt-5'>
      <div className="row justify-content-center pt-5">
      <div className="col-md-12">
      </div>
        <div className="col-md-5 pt-5">
      <h2 className='meta pt-5 pt-md-0 mt-5'>About</h2>
          {/* <h2 className='meta mb-5 pb-lg-5 pt-md-3 pt-lg-5'>About</h2> */}
          {/* <h4 className='meta pb-4 mt-5'>MetaZeus 3D NFT: Building a GameFi Ecosystem on zkSync</h4>
          <p className='pb-4'>
          Metazeus is poised to redefine the web3 gaming landscape by incorporating an array of gamified experiences on 
          the zkSync platform. At the heart of this gaming ecosystem lies the Metazeus 3D collection, serving as the core 
          pillar and connecting element for all gaming activities. As the esteemed cardinals of this vibrant ecosystem, 
          holders of the Metazeus 3D collection will enjoy an elevated status, gaining exclusive access to unique 
          opportunities and benefits within the Gamer Corner.
          </p> */}

          <h4 className='meta pb-4 mt-5'>Manifesto</h4>

          <p>EXPLORE THE SHIFTING LANDSCAPE + JOIN THE MYTHIC WEB3 MOVEMENT!</p>

          <p> EMBRACE UNCERTAINTY + RIDE THE LIGHTNING BOLTS OF CHANGE! </p>

          <p> LET’S GO, ANON… WAGMI! </p>

        </div>
        <div className="col-md-6 offset-md-1">
          <img src="assets/images/about.png" className='w-100' alt="" />
        </div>
      </div>
      </section>

      
    {/* <section>
    <div className="team-container mt-md-5 py-5">
      <h4 className='meta pb-4'>team</h4>

    <div className="grid">
    <div className="team">
      <div className="team-img br-20">
        <img className='br-20' src="assets/images/t1.png" alt="" />
      </div>
      <div className="team-content">
        <div className="name">Pegasus</div>
        <div className="rank"><h6>Web3 Wizard</h6></div>
        <ul>
        <li>Shadowy Supercoder – unless it’s r’Aaave time</li>
          <li>AI-tinkering fan</li>
          <li>Making farming great again</li>
        </ul>
        <div className="team-link">
        <a href="https://twitter.com/_pegasus_one" target={"_blank"}><i className="fa-brands fa-twitter"></i></a>
          <a href="https://discordapp.com/users/pegasus#1842" target={"_blank"}><i className="fa-brands fa-discord"></i></a>
        </div>
      </div>
    </div>

    <div className="team">
      <div className="team-img br-20">
        <img className='br-20' src="assets/images/t2.png" alt="" />
      </div>
      <div className="team-content">
        <div className="name">Apollo</div>
        <div className="rank"><h6>AR King</h6></div>
        <ul>
        <li>MEV’d Crypto Kitties before it was cool</li>
            <li>Idea-generator</li>
            <li>Crypto Dad</li>
        </ul>
        <div className="team-link">
        <a href="https://twitter.com/apollo_metazeus" target={"_blank"}><i className="fa-brands fa-twitter"></i></a>
          <a href="https://discordapp.com/users/apr-hunter23#7122" target={"_blank"}><i className="fa-brands fa-discord"></i></a>
        </div>
      </div>
      
    </div>

    <div className="team">
      <div className="team-img br-20">
        <img className='br-20' src="assets/images/t3.png" alt="" />
      </div>
      <div className="team-content">
        <div className="name">0xSyntellect</div>
        <div className="rank"><h6>Overanalyzer</h6></div>
        <ul>
            <li>Train By Day, Degen By Night</li>
            <li>From NFT to DeFi, back to useless JPEGS</li>
            <li>Sold USDC @ 0.9</li>
        </ul>
        <div className="team-link">
          <a href="https://twitter.com/0xsyntellect/" target={"_blank"}><i className="fa-brands fa-twitter"></i></a>
          <a href="https://discordapp.com/users/0xSyntellect#4768" target={"_blank"}><i className="fa-brands fa-discord"></i></a>
        </div>
      </div>
    </div>

    <div className="team">
      <div className="team-img br-20">
        <img className='br-20' src="assets/images/t4.png" alt="" />
      </div>
      <div className="team-content">
        <div className="name">Trustless</div>
        <div className="rank"><h6>Script Gorilla</h6></div>
        <ul>
            <li>Code is Law</li>
            <li>Breaking Down Centralization</li>
            <li>Rather attached to his JPEGs</li>
        </ul>
        <div className="team-link">
        <a href="https://discordapp.com/users/pegasus#1842" target={"_blank"}><i className="fa-brands fa-discord"></i></a>
        </div>
      </div>
    </div>


    <div className="team">
      <div className="team-img br-20">
        <img className='br-20' src="assets/images/t5.png" alt="" />
      </div>
      <div className="team-content">
        <div className="name">Oiishi</div>
        <div className="rank"><h6>Buildor & Executor</h6></div>
        <ul>
            <li>Saw the fall of TheDAO</li>
            <li>Midjourney addict</li>
            <li>Web3 by day, Cessna172 by night</li>
        </ul>
        <div className="team-link">
          <a href="https://discordapp.com/users/oiishi#7866" target={"_blank"}><i className="fa-brands fa-discord"></i></a>
        </div>
      </div>
     
    </div>

    <div className="team">
      <div className="team-img br-20">
        <img className='br-20' src="assets/images/t6.png" alt="" />
      </div>
      <div className="team-content">
        <div className="name">Saiorose</div>
        <div className="rank"><h6>Community Fairy</h6></div>
        <ul>
            <li>P2E Geek</li>
            <li>Can explain NFTs to your grandma</li>
            <li>Queen of memes</li>
        </ul>
        <div className="team-link">
          <a href="https://discordapp.com/users/Saoirse#0014" target={"_blank"}><i className="fa-brands fa-discord"></i></a>
        </div>
      </div>
    </div>

    </div>


    </div>
    </section> */}
    {/* </ReactPageScroller> */}
    </div>



    <div className="d-block d-md-none">
    <section>
      <div className="row justify-content-center align-items-center pt-5">
        <div className="col-md-5 ">
          <h2 className='meta mt-5 mt-md-0'>About</h2>
          {/* <h4 className='meta pb-4'>MetaZeus 3D NFT: Building a GameFi Ecosystem on zkSync</h4>
          <p className='pb-4'>
          Metazeus is poised to redefine the web3 gaming landscape by incorporating an array of gamified experiences on 
          the zkSync platform. At the heart of this gaming ecosystem lies the Metazeus 3D collection, serving as the core 
          pillar and connecting element for all gaming activities. As the esteemed cardinals of this vibrant ecosystem, 
          holders of the Metazeus 3D collection will enjoy an elevated status, gaining exclusive access to unique 
          opportunities and benefits within the Gamer Corner.
          </p> */}

          <h4 className='meta pb-4'>Manifesto</h4>

          <p>EXPLORE THE SHIFTING LANDSCAPE + JOIN THE MYTHIC WEB3 MOVEMENT!</p>

          <p> EMBRACE UNCERTAINTY + RIDE THE LIGHTNING BOLTS OF CHANGE! </p>

          <p> LET’S GO, ANON… WAGMI! </p>

        </div>
        <div className="col-md-6 offset-md-1">
          <img src="assets/images/about.png" className='w-100' alt="" />
        </div>
      </div>
      </section>

      
    {/* <section>
    <div className="team-container mt-md-5 py-5">
      <h4 className='meta pb-4'>team</h4>

    <div className="grid no-scroll">
    <div className="team">
      <div className="team-img br-20">
        <img className='br-20' src="assets/images/t1.png" alt="" />
      </div>
      <div className="team-content">
        <div className="name">Pegasus</div>
        <div className="rank"><h6>Web3 Wizard</h6></div>
        <ul>
          <li>Shadowy Supercoder – unless it’s r’Aaave time</li>
          <li>AI-tinkering fan</li>
          <li>Making farming great again</li>
        </ul>
        <div className="team-link">
        <a href="https://twitter.com/_pegasus_one" target={"_blank"}><i className="fa-brands fa-twitter"></i></a>
          <a href="https://discordapp.com/users/pegasus#1842" target={"_blank"}><i className="fa-brands fa-discord"></i></a>
        </div>
      </div>
    </div>

    <div className="team">
      <div className="team-img br-20">
        <img className='br-20' src="assets/images/t2.png" alt="" />
      </div>
      <div className="team-content">
        <div className="name">Apollo</div>
        <div className="rank"><h6>AR King</h6></div>
        <ul>
            <li>MEV’d Crypto Kitties before it was cool</li>
            <li>Idea-generator</li>
            <li>Crypto Dad</li>
        </ul>
        <div className="team-link">
        <a href="https://twitter.com/apollo_metazeus" target={"_blank"}><i className="fa-brands fa-twitter"></i></a>
          <a href="https://discordapp.com/users/apr-hunter23#7122" target={"_blank"}><i className="fa-brands fa-discord"></i></a>
        </div>
      </div>
      
    </div>

    <div className="team">
      <div className="team-img br-20">
        <img className='br-20' src="assets/images/t3.png" alt="" />
      </div>
      <div className="team-content">
        <div className="name">0xSyntellect</div>
        <div className="rank"><h6>Overanalyzer</h6></div>
        <ul>
            <li>Train By Day, Degen By Night</li>
            <li>NFT to DeFi, back to useless JPEGs</li>
            <li>Sold USDC @ 0.9</li>
        </ul>
        <div className="team-link">
          <a href="https://twitter.com/0xsyntellect/" target={"_blank"}><i className="fa-brands fa-twitter"></i></a>
          <a href="https://discordapp.com/users/0xSyntellect#4768" target={"_blank"}><i className="fa-brands fa-discord"></i></a>
        </div>
      </div>
    </div>

    <div className="team">
      <div className="team-img br-20">
        <img className='br-20' src="assets/images/t4.png" alt="" />
      </div>
      <div className="team-content">
        <div className="name">Trustless</div>
        <div className="rank"><h6>Script Gorilla</h6></div>
        <ul>
            <li>Code is Law</li>
            <li>Breaking down centralization</li>
            <li>Rather attached to his JPEGs</li>
        </ul>
        <div className="team-link">
        <a href="https://discordapp.com/users/pegasus#1842" target={"_blank"}><i className="fa-brands fa-discord"></i></a>
        </div>
      </div>
    </div>


    <div className="team">
      <div className="team-img br-20">
        <img className='br-20' src="assets/images/t5.png" alt="" />
      </div>
      <div className="team-content">
        <div className="name">Oiishi</div>
        <div className="rank"><h6>Buildor & Executor</h6></div>
        <ul>
            <li>Saw the fall of TheDAO</li>
            <li>Midjourney addict</li>
            <li>Web3 by day, Cessna172 by night</li>
        </ul>
        <div className="team-link">
          <a href="https://discordapp.com/users/oiishi#7866" target={"_blank"}><i className="fa-brands fa-discord"></i></a>
        </div>
      </div>
     
    </div>

    <div className="team">
      <div className="team-img br-20">
        <img className='br-20' src="assets/images/t6.png" alt="" />
      </div>
      <div className="team-content">
        <div className="name">Saiorose</div>
        <div className="rank"><h6>Community Fairy</h6></div>
        <ul>
            <li>P2E Geek</li>
            <li>Can explain NFTs to your grandma</li>
            <li>Queen of memes</li>
        </ul>
        <div className="team-link">
          <a href="https://discordapp.com/users/Saoirse#0014" target={"_blank"}><i className="fa-brands fa-discord"></i></a>
        </div>
      </div>
    </div>

    </div>


    </div>
    </section> */}
    </div>


    </div>}
    
    </>
  )
}

export default About