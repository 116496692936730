import React, { useEffect, useState } from 'react';

const TermsConditions = () => {
  const [animate, setAnimate] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setAnimate(false);
    }, 1000);
  }, []);

  return (
    <>
      <div
        className={`${animate ? 'scale-in-hor-right' : 'scale-out-hor-left'}`}
      ></div>

      {!animate && (
        <div className="container fade-in pt-5 terms-conditions">
          <section className="section card-page grid-page mt-5 pt-5">
            <div className="container">
              <h3 className="meta mb-4 text-center">
                MetaZeus Terms and Conditions
              </h3>

              <section>
                <p>
                  The information contained in or provided on or through the
                  MetaZeus Mintpass (as defined below) is not intended to be and
                  does not constitute financial advice, trading advice, or any
                  other type of advice.
                </p>
                <p>
                  MetaZeus (as defined below) is not liable for any possible
                  misinterpretation of the Terms & Conditions.
                </p>
                <p>
                  MetaZeus is not a wallet provider, custodial, exchange,
                  broker, money services business, bank, security firm, asset
                  manager, portfolio manager or an investment advisor.{' '}
                </p>
                <p>
                  MetaZeus is not a party to any agreement between any users.
                  You bear full responsibility for verifying the identity,
                  legitimacy, and authenticity of assets that you purchase from
                  third-party sellers using the Service and we make no claims
                  about the identity, legitimacy, functionality, or authenticity
                  of users or rewards you may receive visible on the Service.
                </p>
                <p>
                  MetaZeus does not guarantee that any unauthorized copying, use
                  or distribution of content submitted by you or by third
                  parties will not take place. In addition, MetaZeus is not
                  responsible for the content or conduct, whether online or
                  offline, of any user of the Services or other site or services
                  that may be referenced, or linked to, in the Services.
                </p>
                <p>
                  Because MetaZeus has no control over such sites and services,
                  you acknowledge and agree that MetaZeus is not responsible for
                  the availability of such external sites or services, and does
                  not endorse and is not responsible or liable for any content,
                  advertising, products or other materials on or available from
                  such sites or services. We encourage you to be aware of when
                  you leave the Services, and to read the terms and conditions
                  and privacy policy of any third-party website or service that
                  you visit.
                </p>
              </section>
              <section>
                <h4 className="meta mb-4 text-left">MetaZeus NFTs Defined</h4>
                <p>
                  “MetaZeus NFT(s)” refers to MetaZeus Mintpass NFT and MetaZeus
                  NFT collections of digital artworks running on the Ethereum
                  blockchain. Through the MetaZeus Mintpass, users, businesses
                  and third parties can get access to a gamified minting
                  experience of MetaZeus NFT.
                </p>
                <p>
                  MetaZeus License Agreement: These terms and conditions
                  (“Terms”) define the relationship between Alpine Quant Labs
                  A.G. (“MetaZeus”), and any person (“You”, “User”) interacting
                  with:
                </p>
                <ul>
                  <li>(i) Websites owned and operated by MetaZeus;</li>
                  <li>
                    (ii) Social media accounts or pages operated by MetaZeus;
                  </li>
                  <li>
                    (iii) MetaZeus operated smart contracts on the Ethereum
                    network; or
                  </li>
                  <li>(iv) Any and all MetaZeus operated services.</li>
                </ul>
                <p>
                  Additionally, these terms and conditions define the
                  relationship between MetaZeus and any person who maintains
                  ownership of any MetaZeus NFTs (“owners”).
                </p>
              </section>
              <section>
                <h4 className="meta mb-4 text-left">
                  Responsibility of Ownership
                </h4>
                <p>
                  Owners recognize that they are solely responsible for the
                  security of their MetaZeus NFTs, as well as the safety,
                  protection, and management of their Ethereum wallets, which
                  includes any associated passwords, seed phrases, seed words,
                  or private keys.
                </p>
                <p>
                  MetaZeus, shall not, in any event whatsoever, be held
                  responsible for any risk associated with yours or another’s
                  access and/or use of your Ethereum wallet whether through the
                  interaction of a MetaZeus operated service or otherwise. In
                  the unfortunate event that your MetaZeus NFT is lost, stolen,
                  transferred, or otherwise remains inaccessible, MetaZeus
                  cannot retrieve it for you.
                </p>
                <section>
                  <h5 className="meta mb-4 text-left">1) Ownership:</h5>
                  <div className="ownership-flex">
                    <p>
                      By purchasing or lawfully receiving a MetaZeus NFT,
                      whether through a MetaZeus operated website, or
                      third-party website, and maintaining verifiable ownership
                      of the Ethereum wallet in which the MetaZeus NFT is
                      associated you own the MetaZeus NFT, as well as the Art,
                      subject to the definitions and exceptions below.
                    </p>
                  </div>
                  <div>
                    <h6 className="meta mb-4 text-left">A) Personal Use:</h6>
                    <p>
                      Subject to owners continued compliance with these Terms
                      and the below defined Ownership Exceptions, MetaZeus
                      grants owners a worldwide, royalty-free license to use,
                      copy, and display the MetaZeus NFT owned, along with any
                      extensions they choose to create or use, solely for the
                      following purposes:
                    </p>
                    <ul>
                      <li>(i) for your own personal, non-commercial use;</li>
                      <li>
                        (ii) as part of a marketplace that permits the purchase
                        and sale of your MetaZeus NFT, provided that the
                        marketplace cryptographically verifies each MetaZeus NFT
                        owner’s rights to display the Art for their MetaZeus NFT
                        to ensure that only the actual owner can display the
                        art; or
                      </li>
                      <li>
                        (iii) as part of a third party website or application
                        that permits the inclusion, involvement, or
                        participation of your MetaZeus NFT, provided that the
                        website/application cryptographically verifies each
                        MetaZeus NFT owner’s rights to display the Art for their
                        MetaZeus NFT to ensure that only the actual owner can
                        display the art, and provided that the Art is no longer
                        visible once the owner of the MetaZeus NFT leaves the
                        website/application.
                      </li>
                    </ul>
                  </div>
                  <div>
                    <h6 className="meta mb-4 text-left">B) Commercial Use:</h6>
                    <p>
                      Subject to owners continued compliance with these Terms
                      and the below defined Ownership Exceptions, MetaZeus
                      grants owners a worldwide license to use, copy, and
                      display the purchased Art for the purpose of creating
                      derivative works based upon the Art (“Commercial Use”).
                    </p>
                    <p>
                      Examples of such ‘Commercial Use’ would be the use of the
                      Art to produce and sell merchandise products (T-Shirts
                      etc.) displaying copies of the Art. For the sake of
                      clarity, nothing in this Section will be deemed to
                      restrict you from:
                    </p>
                    <ul>
                      <li>
                        (i) owning or operating a marketplace that permits the
                        use and sale of MetaZeus NFT generally, provided that
                        the marketplace cryptographically verifies each MetaZeus
                        NFT owner’s rights to display the Art for their MetaZeus
                        NFT to ensure that only the actual owner can display the
                        Art;
                      </li>
                      <li>
                        (ii) owning or operating a third party website or
                        application that permits the inclusion, involvement, or
                        participation of MetaZeus NFT generally, provided that
                        the third party website or application cryptographically
                        verifies each MetaZeus NFT owner’s rights to display the
                        Art for their MetaZeus NFT to ensure that only the
                        actual owner can display the Art, and provided that the
                        Art is no longer visible once the owner of the Purchased
                        MetaZeus NFT leaves the website/application; or
                      </li>
                      <li>(iii) earning revenue from any of the foregoing.</li>
                    </ul>
                  </div>
                  <div>
                    <h6 className="meta mb-4 text-left">
                      C) Ownership Exceptions:
                    </h6>
                    <p>
                      Both Personal Use and Commercial Use of each MetaZeus NFT
                      as defined above are subject to the following exceptions:
                    </p>
                    <ul>
                      <li>
                        (i) owners <b>do not own individual traits</b> as
                        defined within the MetaZeus NFT metadata e.g. “eyes”,
                        “head”, “hands” etc. or any individual images, layers,
                        or artworks used in the development of MetaZeus NFTs;
                        these individual{' '}
                        <b>traits may not be used exclusively</b>, added to
                        existing MetaZeus NFTs, used to create derivative works,
                        or altered i.e. the owner of the MetaZeus NFT artwork
                        owns the MetaZeus NFT artwork and only the MetaZeus NFT
                        artwork;
                      </li>
                      <li>
                        (ii) the use of artwork developed and distributed by
                        MetaZeus that are unowned by the individual shall not be
                        used, including but not limited to: logos, marketing
                        material, trademarks, and/or website material;
                      </li>
                      <li>
                        (iii) owners shall not engage in any material, venture,
                        product and/ or service which is unlawful, fraudulent,
                        libelous, defamatory, obscene, pornographic, profane,
                        threatening, abusive, hateful, offensive, objectionable,
                        or unreasonable at the discretion of MetaZeus; or.
                      </li>
                      <li>
                        (iv) owners shall not infringe upon any intellectual
                        property rights, proprietary rights or confidentiality
                        obligations.
                      </li>
                    </ul>
                    <p>
                      Furthermore, owners shall indemnify and defend MetaZeus
                      from and against any and all claims, damages, proceedings,
                      loss or costs arising from any such prohibited use. Owners
                      shall not use the MetaZeus NFTs in any way that could be
                      construed as being adverse, negative, or derogatory to the
                      image and/or reputation of MetaZeus
                    </p>
                  </div>
                </section>
                <section>
                  <h5 className="meta mb-4 text-left">2) Obligations:</h5>
                  <p>
                    As a holder/collector of MetaZeus you are solely responsible
                    for your own conduct while accessing or MetaZeus’s Site and
                    for any consequences thereof. You agree to use the Site,
                    social medias, and digital assets only for purposes that are
                    legal, proper and in accordance with these Terms and any
                    applicable laws or regulations. By way of example, and not
                    as a limitation, you may not, and may not allow any third
                    party to:
                  </p>
                  <ul>
                    <li>
                      (i) send, upload, distribute or disseminate any unlawful,
                      defamatory, harassing, abusive, fraudulent, hateful,
                      violent, obscene, or otherwise objectionable content;
                    </li>
                    <li>
                      (ii) distribute viruses, worms, defects, Trojan horses,
                      corrupted files, hoaxes, or any other items of a
                      destructive or deceptive nature;
                    </li>
                    <li>(iii) impersonate another person;</li>
                    <li>
                      (iv) upload, post, transmit or otherwise make available
                      through the Site any content that infringes the
                      intellectual property or proprietary rights of any party
                      or otherwise violates the legal rights of others;
                    </li>
                    <li>
                      (v) engage in, promote, or encourage illegal activity
                      (including, without limitation, money laundering);
                    </li>
                    <li>(vi) interfere with other users' use of the Site;</li>
                    <li>
                      (vii) use the Site for any unauthorized commercial
                      purpose;
                    </li>
                    <li>
                      (viii) modify, adapt, translate, or reverse engineer any
                      portion of the Site;
                    </li>
                    <li>
                      (ix) remove any copyright, trademark or other proprietary
                      rights notices contained in or on the Site or any part of
                      it;
                    </li>
                    <li>
                      (x) use any technology to collect information about the
                      Site’s for any unauthorized purpose;
                    </li>
                    <li>
                      (xi) access or use the Site for the purpose of creating a
                      product or service that is competitive with any of our
                      products or services.
                    </li>
                  </ul>
                  <p>
                    If you engage in any of the activities prohibited by this
                    Section, we may, at our sole and absolute discretion,
                    without notice to you, and without limiting any of our other
                    rights or remedies at law or in equity, immediately suspend
                    or terminate your user account.
                  </p>
                </section>
                <section>
                  <h5 className="meta mb-4 text-left">3) Fees and Payment:</h5>
                  <p>
                    A. If you elect to purchase a MetaZeus NFT, any financial
                    transactions that you engage in will be conducted solely
                    through the Ethereum network. We will have no insight into,
                    or control over these payments or transactions. By
                    consequence we do not have the ability to reverse any of
                    these transactions. We will have no liability to you, or to
                    any other third party, for any claims or damages that may
                    arise as the result of any transactions that you engage on,
                    or any other transactions that you conduct via the Ethereum
                    network.
                  </p>
                  <p>
                    B. Ethereum requires the payment of a transaction fee (a
                    “Gas Fee”) for every transaction that occurs on the Ethereum
                    network. The ‘Gas Fee’ funds the network of computers that
                    run the decentralized Ethereum network. This means that you
                    will need to pay a Gas Fee for each transaction.
                  </p>
                </section>
                <section>
                  <h5 className="meta mb-4 text-left">4) Disclaimers:</h5>
                  <p>
                    A. YOU EXPRESSLY UNDERSTAND AND AGREE THAT YOUR ACCESS TO
                    AND USE OF THE SITE IS AT YOUR SOLE RISK, AND THAT THE SITE
                    IS PROVIDED "AS IS" AND "AS AVAILABLE" WITHOUT WARRANTIES OF
                    ANY KIND, WHETHER EXPRESS OR IMPLIED. TO THE FULLEST EXTENT
                    PERMISSIBLE PURSUANT TO APPLICABLE LAW, WE MAKE NO EXPRESS
                    WARRANTIES AND HEREBY DISCLAIM ALL IMPLIED WARRANTIES
                    REGARDING THE SITE AND ANY PART OF IT (INCLUDING, WITHOUT
                    LIMITATION, THE SITE, ANY SMART CONTRACT, OR ANY EXTERNAL
                    WEBSITES), INCLUDING THE IMPLIED WARRANTIES OF
                    MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE,
                    NON-INFRINGEMENT, CORRECTNESS, ACCURACY, OR RELIABILITY.
                    WITHOUT LIMITING THE GENERALITY OF THE FOREGOING, WE, OUR
                    SUBSIDIARIES, AFFILIATES, AND LICENSORS DO NOT REPRESENT OR
                    WARRANT TO YOU THAT:
                  </p>
                  <ul>
                    <li>
                      (I) YOUR ACCESS TO OR USE OF THE SITE WILL MEET YOUR
                      REQUIREMENTS,
                    </li>
                    <li>
                      (II) YOUR ACCESS TO OR USE OF THE SITE WILL BE
                      UNINTERRUPTED, TIMELY, SECURE OR FREE FROM ERROR,
                    </li>
                    <li>
                      (III) USAGE DATA PROVIDED THROUGH THE SITE WILL BE
                      ACCURATE,
                    </li>
                    <li>
                      (IV) THE SITE OR ANY CONTENT, SERVICES, OR FEATURES MADE
                      AVAILABLE ON OR THROUGH THE SITE ARE FREE OF VIRUSES OR
                      OTHER HARMFUL COMPONENTS, OR
                    </li>
                    <li>
                      (V) THAT ANY DATA THAT YOU DISCLOSE WHEN YOU USE THE SITE
                      WILL BE SECURE. SOME JURISDICTIONS DO NOT ALLOW THE
                      EXCLUSION OF IMPLIED WARRANTIES IN CONTRACTS WITH
                      CONSUMERS, SO SOME OR ALL OF THE ABOVE EXCLUSIONS MAY NOT
                      APPLY TO YOU.
                    </li>
                  </ul>
                  <p>
                    B. YOU ACCEPT THE INHERENT SECURITY RISKS OF PROVIDING
                    INFORMATION AND DEALING ONLINE OVER THE INTERNET AND AGREE
                    THAT WE HAVE NO LIABILITY OR RESPONSIBILITY FOR ANY BREACH
                    OF SECURITY UNLESS IT IS DUE TO OUR WILLFUL MISCONDUCT.
                  </p>
                  <p>
                    C. WE WILL NOT BE RESPONSIBLE OR LIABLE TO YOU FOR ANY
                    LOSSES YOU INCUR AS THE RESULT OF YOUR USE OF THE ETHEREUM
                    NETWORK NOR DO WE HAVE NO CONTROL OVER AND MAKE NO
                    GUARANTEES REGARDING ANY SMART CONTRACTS.
                  </p>
                </section>
                <section>
                  <h5 className="meta mb-4 text-left">
                    5) Limitation of Liability:
                  </h5>
                  <p>
                    A. YOU UNDERSTAND AND AGREE THAT WE WILL NOT BE LIABLE TO
                    YOU OR TO ANY THIRD PARTY FOR ANY INDIRECT, INCIDENTAL,
                    SPECIAL, CONSEQUENTIAL, OR EXEMPLARY DAMAGES WHICH YOU MAY
                    INCUR, HOWSOEVER CAUSED AND UNDER ANY THEORY OF LIABILITY,
                    INCLUDING, WITHOUT LIMITATION, ANY LOSS OF PROFITS (WHETHER
                    INCURRED DIRECTLY OR INDIRECTLY), LOSS OF GOODWILL OR
                    BUSINESS REPUTATION, LOSS OF DATA, COST OF PROCUREMENT OF
                    SUBSTITUTE GOODS OR SERVICES, OR ANY OTHER INTANGIBLE LOSS,
                    EVEN IF WE HAVE BEEN ADVISED OF THE POSSIBILITY OF SUCH
                    DAMAGES.
                  </p>
                  <p>
                    B. YOU AGREE THAT OUR TOTAL, AGGREGATE LIABILITY TO YOU FOR
                    ANY AND ALL CLAIMS ARISING OUT OF OR RELATING TO THESE TERMS
                    OR YOUR ACCESS TO OR USE OF (OR YOUR INABILITY TO ACCESS OR
                    USE) ANY PORTION OF THE SITE, WHETHER IN CONTRACT, TORT,
                    STRICT LIABILITY, OR ANY OTHER LEGAL THEORY, IS LIMITED TO
                    THE GREATER OF (A) THE AMOUNTS YOU ACTUALLY PAID US UNDER
                    THESE TERMS IN THE 12 MONTH PERIOD PRECEDING THE DATE THE
                    CLAIM AROSE, OR (B) $500.
                  </p>
                  <p>
                    C. YOU ACKNOWLEDGE AND AGREE THAT WE HAVE MADE THE SITE
                    AVAILABLE TO YOU AND ENTERED INTO THESE TERMS IN RELIANCE
                    UPON THE WARRANTY DISCLAIMERS AND LIMITATIONS OF LIABILITY
                    SET FORTH HEREIN. WE WOULD NOT BE ABLE TO PROVIDE THE SITE
                    TO YOU WITHOUT THESE LIMITATIONS.
                  </p>
                </section>
                <section>
                  <h5 className="meta mb-4 text-left">6) Risk Assumption:</h5>
                  <p>You accept and acknowledge each of the following:</p>
                  <p>
                    A. To the extent that you sell your MetaZeus NFT, please be
                    aware that the prices of NFTs are extremely volatile and
                    fluctuations in the prices of other NFTs and impact the
                    price of your MetaZeus NFT both positively and negatively.
                    Given the volatility, NFTs such as MetaZeus should not be
                    considered an investment. You assume all risks in that
                    connection.
                  </p>
                  <p>
                    B. Ownership of a MetaZeus NFT confers ownership of digital
                    artwork only. Accordingly, no information on this Site (or
                    any other documents mentioned therein) is or may be
                    considered advice or an invitation to enter into an
                    agreement for any investment purpose. Further, nothing on
                    this Site qualifies or is intended to be an offering of
                    securities in any jurisdiction nor does it constitute an
                    offer or an invitation to purchase shares, securities, or
                    other financial products. Due to the artistic nature of the
                    project, MetaZeus has not been registered with or approved
                    by any regulator in any jurisdiction. It remains your sole
                    responsibility to assure that the purchase of the MetaZeus
                    NFT and the associated art is compliant with laws and
                    regulations in your jurisdiction.
                  </p>
                  <p>
                    C. You assume all risks associated with using an
                    Internet-based currency, including, but not limited to, the
                    risk of hardware, solware and Internet connections, the risk
                    of malicious solware introduction, and the risk that third
                    parties may obtain unauthorized access to information stored
                    within your wallet.
                  </p>
                  <p>
                    D. NFTs, cryptocurrencies and blockchain technology are
                    relatively new and the regulatory landscape is unsettled.
                    New regulations could negatively impact such technologies
                    impacting the value for your MetaZeus NFT. You understand
                    and accept all risk in that regard
                  </p>
                  <p>
                    E. You assume all responsibility for any adverse effects of
                    disruptions or other issues impacting Ethereum or the
                    Ethereum platform.
                  </p>
                </section>
                <section>
                  <h5 className="meta mb-4 text-left">7) Indemnification:</h5>
                  <p>
                    You agree to hold harmless and indemnify MetaZeus and its
                    subsidiaries, affiliates, officers, agents, employees,
                    advertisers, licensors, suppliers or partners from and
                    against any claim, liability, loss, damage (actual and
                    consequential) of any kind or nature, suit, judgment,
                    litigation cost, and reasonable attorneys' fees arising out
                    of or in any way related to (i) your breach of these Terms,
                    (ii) your misuse of the Site, or (iii) your violation of
                    applicable laws, rules or regulations in connection with
                    your access to or use of the Site.
                  </p>
                </section>
                <section>
                  <h5 className="meta mb-4 text-left">
                    8) Changes to the Terms and Conditions:
                  </h5>
                  <p>
                    We may make changes to the Terms at our discretion. Please
                    check these Terms periodically for changes. Any changes to
                    the Terms will apply on the date that they are made, and
                    your continued access to or use after the Terms have been
                    updated will constitute your binding acceptance of the
                    updates. If you do not agree to any revised Terms, you may
                    not access or use the Site.
                  </p>
                </section>
                <section>
                  <h5 className="meta mb-4 text-left">9) Children:</h5>
                  <p>
                    Our Site is not intended for children. You must be at least
                    18 years old to access this Site or purchase a MetaZeus NFT.
                    If you are under 18 years old, you are not permitted to use
                    this Site for any reason. By accessing the Site, you
                    represent and warrant that you are at least 18 years of age.
                  </p>
                </section>
                <section>
                  <h5 className="meta mb-4 text-left">
                    10) Dispute Resolution; Arbitration :
                  </h5>
                  <p>
                    All disputes arising out of or in connection with these
                    Terms, including without limitation your access or use of
                    the Site, or to any products sold or distributed through the
                    Site, will be referred to and finally resolved by
                    arbitration under the rules of the ASA - Swiss Arbitration
                    Association. The case will be adjudicated by a single
                    arbitrator and will be administered by the ASA - Swiss
                    Arbitration Association in accordance with its applicable
                    rules. Each party will cover its own fees and costs
                    associated with the arbitration proceedings. The place of
                    arbitration will be Zug, Switzerland. The award of the
                    arbitrator will be final and binding, and any judgment on
                    the award rendered by the arbitrator may be entered in any
                    court of competent jurisdiction. Notwithstanding the
                    foregoing, we may seek and obtain injunctive relief in any
                    jurisdiction in any court of competent jurisdiction.
                  </p>
                  <p>
                    WITH RESPECT TO ANY DISPUTE ARISING OUT OF OR RELATED TO
                    THESE TERMS, INCLUDING WITHOUT LIMITATION DISPUTES RELATED
                    TO THE SITE OR ANY PRODUCTS SOLD OR DISTRIBUTED THROUGH THE
                    SITE, OR THE SMART CONTRACTS: (I) YOU HEREBY EXPRESSLY GIVE
                    UP YOUR RIGHT TO HAVE A TRIAL BY JURY; AND (II) YOU HEREBY
                    EXPRESSLY GIVE UP YOUR RIGHT TO PARTICIPATE AS A MEMBER OF A
                    CLASS OF CLAIMANTS IN ANY LAWSUIT, INCLUDING BUT NOT LIMITED
                    TO CLASS ACTION LAWSUITS INVOLVING ANY SUCH DISPUTE.
                  </p>
                </section>
              </section>
            </div>
          </section>
        </div>
      )}
    </>
  );
};

export default TermsConditions;
