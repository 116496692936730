import React, { useEffect, useRef, useState } from "react";
import "./App.css";
import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";
import Home from "./Pages/Home";
import Header from "./Components/Header";
import About from "./Pages/About";
import Vision from "./Pages/Vision";
import Faqs from "./Pages/Faqs";
import ArcheType from "./Pages/ArcheType";
import Roadmap from "./Pages/Roadmap";
import Mint from "./Pages/Mint";
import TermsConditions from "./Pages/TermsConditions";
import MetaZeus from "./Pages/Metazeus-3D";

function App() {
  const [loader, setLoader] = useState(true);
  const [bars, setBars] = useState(true);

  const [playing, setPlaying] = useState(true);

  const url = "assets/drop-it.mp3";

  const audioRef = useRef(new Audio(url));

  const play = () => {
    setPlaying(false);
    audioRef.current.play();
  };

  const pause = () => {
    setPlaying(true);
    audioRef.current.pause();
  };

  useEffect(() => {
    setTimeout(() => {
      setLoader(false);
    }, 3000);
  }, []);

  return (
    <Router>
      {!loader && (
        <>
          <Header />
          <Routes>
            {/* <Route path="/" element={<Home />} />   */}
            <Route path="/" element={<About />} />
            <Route path="/vision" element={<Vision />} />
            {/* <Route path="/archetype" element={<ArcheType />} /> */}
            <Route path="/roadmap" element={<Roadmap />} />
            {/* <Route path="/metazeus" element={<MetaZeus />} /> */}
            <Route path="/metazeus" element={<MetaZeus />} />
            <Route path="/faq" element={<Faqs />} />
            <Route path="/terms-conditions" element={<TermsConditions />} />
          </Routes>

          <div className="links-container">
            <div className="link-indiv">
              <div className="link">
                <a
                  href="https://twitter.com/joinmetazeus/"
                  target={"_blank"}
                  rel="noreferrer"
                >
                  <i className="fa-brands fa-twitter"></i>
                </a>
                <a
                  href="https://discord.com"
                  target={"_blank"}
                  rel="noreferrer"
                >
                  <i className="fa-brands fa-discord"></i>
                </a>
                <a href="https://raritysniper.com/nft-drops-calendar" hidden>
                  NFT Drops
                </a>
                <div className="mp3-container">
                  <div
                    className="center-me center-me"
                    onClick={() => {
                      setBars(!bars);
                      playing ? play() : pause();
                    }}
                    id="pButton"
                  >
                    <div
                      className={`${bars ? "equalizerr" : "equalizer"}`}
                    ></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}

      {loader && (
        <div className="preloader">
          <img
            src="assets/images/loader.png"
            className="dark-logo px-1"
            alt="logo-site"
          />
          <img
            src="assets/images/loader-logo-d.svg"
            className="light-logo px-1"
            alt="logo-site"
          />
          <div className="loader"></div>
        </div>
      )}
    </Router>
  );
}

export default App;
