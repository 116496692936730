import React, { useState } from 'react';

import { Link, NavLink } from 'react-router-dom';

const Header = () => {
  const homeNav = () => {
    if (document.body.classList.contains('home-sm')) {
      document.body.classList.remove('home-sm');
    } else {
      document.body.classList.add('home-sm');
    }
  };
  const homeNavClose = () => {
    if (document.body.classList.contains('home-sm')) {
      document.body.classList.remove('home-sm');
    }
  };

  return (
    <div className="main-header">
      <nav
        className="navbar navbar-expand-lg transition-all py-4"
        id="navbar-example2"
      >
        <div className="container-fluid px-md-4 pe-md-5">
          <Link to="/" className="navbar-brand">
            <img
              src="assets/images/logo.svg"
              height={40}
              className="dark-logo"
              alt=""
            />
            <img
              src="assets/images/logo-d.svg"
              height={40}
              className="light-logo"
              alt=""
            />
          </Link>
          <button
            className="navbar-toggler text-white p-0"
            type="button"
            onClick={() => {
              homeNav();
            }}
          >
            <span className="iconify" data-icon="humbleicons:bars"></span>
          </button>
          <div
            className="collapse navbar-collapse pe-lg-5"
            id="navbarSupportedContent"
          >
            <div className="d-lg-none d-block text-end p-4 pb-2">
              <a
                href="javascript:void(0)"
                onClick={() => {
                  homeNav();
                }}
                className="cross-btn text-white"
              >
                <span className="iconify" data-icon="akar-icons:cross"></span>
              </a>
            </div>
            <ul className="navbar-nav ms-auto px-md-0 px-5 mb-2 align-items-lg-center gap-4 mb-lg-0">
              {/* <li className="nav-item">
              <NavLink to="/" className="nav-link" onClick={() => homeNavClose()}>Home</NavLink>
              </li> */}
              <li className="nav-item">
                <NavLink
                  to="/"
                  className="nav-link"
                  onClick={() => homeNavClose()}
                >
                  About
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink
                  to="/vision"
                  className="nav-link"
                  onClick={() => homeNavClose()}
                >
                  Vision
                </NavLink>
              </li>
              <li className="nav-item">
                <a
                  href="https://genesis.joinmetazeus.xyz"
                  className="nav-link"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Genesis Claim
                </a>
              </li>
              {/* <li className="nav-item">
                <NavLink
                  to="/archetype"
                  className="nav-link"
                  onClick={() => homeNavClose()}
                >
                  Archetype
                </NavLink>
              </li> */}
              {/* <li className="nav-item">
                <NavLink
                  to="/roadmap"
                  className="nav-link"
                  onClick={() => homeNavClose()}
                >
                  Roadmap
                </NavLink>
              </li> */}
              <li className="nav-item">
                <NavLink
                  to="/metazeus"
                  className="nav-link"
                  onClick={() => homeNavClose()}
                >
                  Metazeus 3D
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink
                  to="/faq"
                  className="nav-link"
                  onClick={() => homeNavClose()}
                >
                  Faq
                </NavLink>
              </li>
              <li className="nav-item">
                <a
                  href="https://opensea.io/collection/metazeusgenesis"
                  className="nav-link"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Genesis OS
                </a>
              </li>
            </ul>
            <div className="d-lg-none d-flex gap-4 p-4 px-5 ">
              <a
                href="https://twitter.com/joinmetazeus/"
                target={'_blank'}
                rel="noreferrer"
              >
                <i className="fa-brands fa-twitter"></i>
              </a>
              <a
                href="https://discord.com/invite/metazeus"
                target={'_blank'}
                rel="noreferrer"
              >
                <i className="fa-brands fa-discord"></i>
              </a>
            </div>
          </div>
        </div>
      </nav>
    </div>
  );
};

export default Header;
