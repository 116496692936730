import React, { useEffect, useState } from 'react'

const Roadmap = () => {

  const [animate, setAnimate] = useState(true);

  useEffect(() => {
  setTimeout(() => {
    setAnimate(false)
    }, 1000);
}, [])

  return (
    <>
<div className={`${animate ? "scale-in-hor-right" : "scale-out-hor-left"}`}></div>
      
      {!animate && <div className="container roadmap fade-in py-5">

      <h2 className='meta mb-4 pt-5'>Roadmap</h2>
      
      <div className="row mb-4">
        <div className="col-md-6 mb-md-0 mb-4">
          <p>
          At MetaZeus, we’re all about defining your own electrifying adventure. <br /><br />

        On our quest to ignite web3’s mythic movement, you’ll find no set menu, rigid timeline or
         planned play – just the freedom to create what you crave. But of course, we aren’t sitting
          around twiddling thumbs – Zeus zaps anyone he finds loitering! <br /><br />

        We innovate and explore new ideas constantly with the flexible creativity that a lack of
         roadmap unlocks. We listen to and adapt to our community’s needs, rather than force some predefined plan upon them.
          </p>
        </div>
        <div className="col-md-6">
          <p>
          What does this mean for you? Probably nothing! <br /><br />

          However, we know some of you need structure – so here's our unstructured structure.
           Like those clouds atop which MetaOlympus stands – there but not, outlined but not forever.
            A roadmap made of rubber – bouncy, bendable and forever open to being guided in the most exciting directions…

          </p>
        </div>
      </div>

      <div className="roadmap-row">
         <div className="roadmap-column br-r">
         <div className="roadmap-content">
          <h4>Non fungible</h4>
          <ul>
            <li>Artwork <span>in progress</span></li>
            <li>Collectibles <span>in progress</span></li>
            <li>vibes</li>
          </ul>
         </div>
         </div>
         <div className="roadmap-column br-l">
         <div className="roadmap-content">
          <h4>culture</h4>
          <ul>
            <li>fashion</li>
            <li>art</li>
            <li>community <span>in progress</span></li>
          </ul>
         </div>
         </div>
         </div>

         <div className="roadmap-row">
         <div className="roadmap-column br-r">
         <div className="roadmap-content">
          <h4>Labs & Branding</h4>
          <ul>
            <li>Partnerships <span>in progress</span></li>
            <li>Alpha club </li>
            <li>Branding <span>in progress</span></li>
          </ul>
         </div>
         </div>
         <div className="roadmap-column br-l">
         <div className="roadmap-content">
          <h4>Gaming</h4>
          <ul>
            <li>gamified experiences</li>
            <li>Leaderboard <span>in progress</span></li>
            <li>skill-based mint </li>
          </ul>
         </div>
         </div>
         </div>

    </div>}

    </>
  )
}

export default Roadmap