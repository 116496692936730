import React, { useEffect, useState } from "react";

const MetaZeus = () => {
  const [animate, setAnimate] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setAnimate(false);
    }, 1000);
  }, []);

  return (
    <>
      <div
        className={`${animate ? "scale-in-hor-right" : "scale-out-hor-left"}`}
      ></div>

      {!animate && (
        <div className="container fade-in pt-5">
          <div className="row justify-content-center align-items-center pt-5 mt-md-5">
            <div className="col-md-10">
              <h2 className="meta mb-3 text-center no-text-transform">
                MetaZeus 3D on zkSync
              </h2>
              <h4 className="meta text-center no-text-transform">
                NFT Sale TBA
              </h4>
              <p className="text-center">
                <a
                  target="_blank"
                  href="https://wl.joinmetazeus.xyz/"
                  className="read-more px-md-4 no-text-transform"
                  rel="noreferrer"
                >
                  Check for Whitelist
                </a>
              </p>
            </div>
            <div className="col-md-7 mb-md-5 mb-4">
              <div className="row">
                <div className="col-md-4 mb-4">
                  <div className="meta-card">
                    <div className="meta-img">
                      <img src="assets/images/m1.png" alt="" />
                    </div>
                    <div className="meta-text">
                      <h6>ZStormRider</h6>
                    </div>
                  </div>
                </div>
                <div className="col-md-4 mb-4">
                  <div className="meta-card">
                    <div className="meta-img">
                      <img src="assets/images/m2.png" alt="" />
                    </div>
                    <div className="meta-text">
                      <h6>ZLightningKing</h6>
                    </div>
                  </div>
                </div>
                <div className="col-md-4 mb-4">
                  <div className="meta-card">
                    <div className="meta-img">
                      <img src="assets/images/m3.png" alt="" />
                    </div>
                    <div className="meta-text">
                      <h6>ZAstralAce</h6>
                    </div>
                  </div>
                </div>
                <div className="col-md-4 mb-4">
                  <div className="meta-card">
                    <div className="meta-img">
                      <img src="assets/images/m4.png" alt="" />
                    </div>
                    <div className="meta-text">
                      <h6>ZThunderSnap</h6>
                    </div>
                  </div>
                </div>
                <div className="col-md-4 mb-4">
                  <div className="meta-card">
                    <div className="meta-img">
                      <img src="assets/images/m5.png" alt="" />
                    </div>
                    <div className="meta-text">
                      <h6>ZCosmicRuler</h6>
                    </div>
                  </div>
                </div>
                <div className="col-md-4 mb-4">
                  <div className="meta-card">
                    <div className="meta-img">
                      <img src="assets/images/m7.png" alt="" />
                    </div>
                    <div className="meta-text">
                      <h6>ZCelestialGuru</h6>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default MetaZeus;
