import React, { useEffect, useState } from "react";

const Faqs = () => {
  const [animate, setAnimate] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setAnimate(false);
    }, 1000);
  }, []);

  return (
    <>
      <div
        className={`${animate ? "scale-in-hor-right" : "scale-out-hor-left"}`}
      ></div>

      {!animate && (
        <div className="container fade-in pt-5">
          {/* Over view */}

          <section className="section card-page grid-page mt-5 pt-5">
            <div className="container">
              <h2 className="meta mb-3 text-center">faq</h2>
{/* 
              <h3 className="meta mb-4 text-center">
                Metazeus skill-based mint
              </h3>

              <div className="grid-cards mb-4">
                <div className="card-box">
                  <div className="in-bg h-100">
                    <div className="upper-part">
                      <div className="item-1 w-100">
                        <a href="javascript:void(0)" className="ph-btn">
                          Phase 01
                        </a>
                      </div>
                    </div>

                    <div className="in-text">
                      <h5>OG+WHITELIST MINTPASS sale</h5>
                      <b>Start Time:</b> <br />
                      <span> 30th March 3 pm UTC </span> <br />
                      <b>Supply: </b>
                      <span> 650 </span> <br />
                      <b>Mintpass Price:</b> <br /> <span>0.077 ETH</span>{" "}
                      <br />
                      <b>Max Mint: </b> <span>2</span> <br />
                      <b>Duration: </b> <span> 3 hours or sellout</span> <br />
                      <i>IMPORTANT:</i>{" "}
                      <span>
                        The Whitelist/OG is for the Mintpass, not for the
                        MetaZeus NFT. You will receive the MetaZeus NFT for free
                        through the Mintpass.
                      </span>
                      <b>OG Bonus:</b>{" "}
                      <span>
                        OGs who mint 2 Passes get 1 additionally airdropped.
                      </span>
                    </div>
                  </div>
                </div>
                <div className="card-box">
                  <div className="in-bg h-100">
                    <div className="upper-part">
                      <div className="item-1 w-100">
                        <a href="javascript:void(0)" className="ph-btn">
                          Phase 02
                        </a>
                      </div>
                    </div>
                    <div className="in-text">
                      <h5>public MINTPASS sale</h5>
                      <b>Start Time:</b> <br />
                      <span> 30th March 6 pm UTC </span> <br />
                      <b>Supply: </b>
                      <span> Remaining from OG+WL Sale </span> <br />
                      <span>Only opens if supply remains.</span>
                    </div>
                  </div>
                </div>
                <div className="card-box">
                  <div className="in-bg">
                    <div className="upper-part">
                      <div className="item-1 w-100">
                        <a href="javascript:void(0)" className="ph-btn">
                          Phase 03
                        </a>
                      </div>
                    </div>

                    <div className="in-text">
                      <h5>gameplay</h5>
                      <b>Start Time: </b>
                      <span> 4th April </span> <br />
                      <b>Duration: </b> <span> 3 weeks</span> <br /> <br />
                      <ul>
                        <li>
                          Play the MetaZeus Runner game using your Mintpass
                          during the 3-week play period.
                        </li>
                        <li>
                          The higher your score in the game, the rarer the NFT
                          you'll receive after the 3 weeks have passed.
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="card-box">
                  <div className="in-bg h-100">
                    <div className="upper-part">
                      <div className="item-1 w-100">
                        <a href="javascript:void(0)" className="ph-btn">
                          Phase 04
                        </a>
                      </div>
                    </div>

                    <div className="in-text">
                      <h5>MetaZeus NFT claim</h5>
                      <b>Time: </b> <span>25th April</span> <br />
                      <b>Cost: </b>{" "}
                      <span>
                        Mintpass Holders are able to claim their MetaZeus NFT at
                        no cost.{" "}
                      </span>{" "}
                      <br />
                      <span>
                        Each Mintpass will translate into one unique NFT from
                        that collection.
                      </span>
                    </div>
                  </div>
                </div>
                <div className="card-box l-c">
                  <div className="in-bg h-100">
                    <div className="upper-part">
                      <div className="item-1 w-100">
                        <a href="javascript:void(0)" className="ph-btn">
                          Phase 05
                        </a>
                      </div>
                    </div>

                    <div className="in-text">
                      <h5>METAZEUS NFT reveal</h5>
                      <b>Time: </b> <span>27th April</span> <br />
                    </div>
                  </div>
                </div>
              </div> */}
            </div>
          </section>

          {/* Faqs */}

          <section className="section faq">
            <div className="container">
              <div className="row">
                <div className="col-lg-6 col-md-7 m-auto">
                  <div className="box-faq">
                    <div className="accordion" id="accordionExample">
                      <div className="accordion-item">
                        <h2 className="accordion-header" id="headingOne">
                          <button
                            className="accordion-button collapsed"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#collapseOne"
                            aria-expanded="false"
                            aria-controls="collapseOne"
                          >
                            What is MetaZeus?
                          </button>
                        </h2>
                        <div
                          id="collapseOne"
                          className="accordion-collapse collapse"
                          aria-labelledby="headingOne"
                          data-bs-parent="#accordionExample"
                        >
                          <div className="accordion-body pe-lg-5 me-lg-5">
                          MetaZeus is set to revolutionize the web3 gaming space by offering a range of gamified 
                          experiences built on the zkSync platform. Central to this gaming ecosystem is the MetaZeus 
                          3D collection, which serves as the foundation and unifying element for all gaming activities. 
                          Holders of the MetaZeus 3D collection will become esteemed cardinals of this thriving ecosystem, 
                          enjoying exclusive access to unique opportunities and benefits within the Gamer Corner.
                          </div>
                        </div>
                      </div>
                      <div className="accordion-item">
                        <h2 className="accordion-header" id="headingTwo">
                          <button
                            className="accordion-button collapsed"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#collapseTwo"
                            aria-expanded="false"
                            aria-controls="collapseTwo"
                          >
                            On which blockchain will MetaZeus 3D NFTs be based?
                          </button>
                        </h2>
                        <div
                          id="collapseTwo"
                          className="accordion-collapse collapse"
                          aria-labelledby="headingTwo"
                          data-bs-parent="#accordionExample"
                        >
                          <div className="accordion-body pe-lg-5 me-lg-5">
                          MetaZeus 3D NFTs will launch on zkSync, the most advanced Layer 2 blockchain 
                          solution that offers unparalleled speed and security for the web3 gaming experience.
                          </div>
                        </div>
                      </div>

                      <div className="accordion-item">
                        <h2 className="accordion-header" id="headingThree">
                          <button
                            className="accordion-button collapsed"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#collapseFour"
                            aria-expanded="false"
                            aria-controls="collapseFour"
                          >
                            What is the total supply of MetaZeus NFTs?
                          </button>
                        </h2>
                        <div
                          id="collapseFour"
                          className="accordion-collapse collapse"
                          aria-labelledby="headingThree"
                          data-bs-parent="#accordionExample"
                        >
                          <div className="accordion-body pe-lg-5 me-lg-5">
                          The total supply of MetaZeus NFTs will be announced soon. Stay tuned for updates!
                          </div>
                        </div>
                      </div>

                      <div className="accordion-item">
                        <h2 className="accordion-header" id="headingThree">
                          <button
                            className="accordion-button collapsed"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#collapseThree"
                            aria-expanded="false"
                            aria-controls="collapseThree"
                          >
                            How can I get whitelisted?
                          </button>
                        </h2>
                        <div
                          id="collapseThree"
                          className="accordion-collapse collapse"
                          aria-labelledby="headingThree"
                          data-bs-parent="#accordionExample"
                        >
                          <div className="accordion-body pe-lg-5 me-lg-5">
                          To secure a spot on the whitelist, engage actively in our community and achieve over 1,200 
                          XP in Zealy. Early supporters who reach this milestone will earn an exclusive role and a whitelist spot. 
                          Additionally, don't miss our pre-mint raffle on our pinned tweet! Start your journey by visiting the MetaZeus 
                          quest board on Zealy: <a href="https://zealy.io/c/metazeus3d/questboard">https://zealy.io/c/metazeus3d/questboard</a>
                          </div>
                        </div>
                      </div>
                      <div className="accordion-item">
                        <h2 className="accordion-header" id="headingThree">
                          <button
                            className="accordion-button collapsed"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#collapseSix"
                            aria-expanded="false"
                            aria-controls="collapseSix"
                          >
                            What is the price of MetaZeus NFTs?
                          </button>
                        </h2>
                        <div
                          id="collapseSix"
                          className="accordion-collapse collapse"
                          aria-labelledby="headingThree"
                          data-bs-parent="#accordionExample"
                        >
                          <div className="accordion-body pe-lg-5 me-lg-5">
                          The price of MetaZeus NFTs will be announced soon. Rest assured, we aim to make it 
                          affordable, and whitelist members will enjoy a more favorable price than the general public.
                          </div>
                        </div>
                      </div>

                      <div className="accordion-item">
                        <h2 className="accordion-header" id="headingThree">
                          <button
                            className="accordion-button collapsed"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#collapseS07"
                            aria-expanded="false"
                            aria-controls="collapseS07"
                          >
                            When is the mint date for MetaZeus NFTs?
                          </button>
                        </h2>
                        <div
                          id="collapseS07"
                          className="accordion-collapse collapse"
                          aria-labelledby="headingThree"
                          data-bs-parent="#accordionExample"
                        >
                          <div className="accordion-body pe-lg-5 me-lg-5">
                          The mint date for MetaZeus NFTs has not been announced yet, 
                          but it's just around the corner. Keep an eye out for updates!
                          </div>
                        </div>
                      </div>

                      <div className="accordion-item">
                        <h2 className="accordion-header" id="headingThree">
                          <button
                            className="accordion-button collapsed"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#collapse09"
                            aria-expanded="false"
                            aria-controls="collapse09"
                          >
                            Where can I find more information about MetaZeus NFTs?
                          </button>
                        </h2>
                        <div
                          id="collapse09"
                          className="accordion-collapse collapse"
                          aria-labelledby="headingThree"
                          data-bs-parent="#accordionExample"
                        >
                          <div className="accordion-body pe-lg-5 me-lg-5">
                          For the latest information and updates on MetaZeus NFTs, join our Discord community. 
                          We're always happy to help and answer any questions you may have.
                          </div>
                        </div>
                      </div>

                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      )}
    </>
  );
};

export default Faqs;
