import React, { useEffect, useRef, useState } from 'react'
import ReactPageScroller from 'react-page-scroller';


const Vision = () => {
  const one = useRef(null);
  const [animate, setAnimate] = useState(true);
  const [currentPage, setCurrentPage] = useState(null);



  

  const handlePageChange = (number:any) => {
    setCurrentPage(number);
  };

  const handleBeforePageChange = (number:any) => {
    console.log(number);
  };




  useEffect(() => {
  setTimeout(() => {
    setAnimate(false)
    }, 1000);
}, [])


  return (
    <>

<div className={`${animate ? "scale-in-hor-right" : "scale-out-hor-left"}`}></div>
    
    {!animate && <div className="container vision">

      <div className="d-none d-md-block">
      <ReactPageScroller
          pageOnChange={(e) => handlePageChange(e)}
          onBeforePageScroll={(e) => handleBeforePageChange(e)}
          containerWidth={"100%"}
          transitionTimingFunction = {"ease"}
          animationTimerBuffer = {10}
        >
    <section>
    <div className="row fade-in pt-5">
      <div className="col-md-12 pt-md-3 pt-lg-0">
      <h2 className='meta pt-5 pt-md-0 mt-5 mt-md-0'>Vision</h2>

      </div>
      <div className="col-md-6 mb-md-0 mb-4">
         <h4 className='meta pb-4 mt-5'>Who is metazeus?</h4>
       <p className='pe-lg-5'>
       A personification of web3 culture, MetaZeus represents each and every degen out there riding narratives,
        seeking alpha and unearthing shifts in the meta. <br /> <br /> And just as he led the gods of Greek mythology,
         Zeus stands as the electrifying meta-ruler of our digital realm – a digital hero with extraordinary
          abilities… <br /><br /> In his honor, we unite a vibrant community of web3 experimenters who together shall
           carve out the mythic movement of web3. Each of our mesmerizing Metas features a momentary snapshot
            of web3 culture, complete with its own rabbit hole. <br /><br /> The only constant is change… Can you keep up, anon?
        </p>
       
      </div>
      <div className="col-md-5 offset-md-1 col-10 mx-auto">
        <img src="assets/images/v1.png" className="w-100"  alt="" />
        </div>
    </div>
  </section>
  <section >
    <div className="row align-items-center pt-5 fade-in">
      <div className="col-md-5 order-md-0 order-1 col-10 mx-auto">
        <img src="assets/images/v2.png" className="w-100"  alt="" />

        </div>
      <div className="col-md-6 offset-md-1 mb-md-0 mb-4">
      <h4 className='meta pb-4'>Community</h4>

        <p className='pe-lg-5'>To succeed in anything, you need people who believe – especially in web3.
           But not just any old people… <br /><br /> You need a tribe of like-minded, passionate folks who feel the energy of your vision,
            share in your common goal – and are deeply inspired to contribute to ongoing growth. <br /><br />
          Let it be known, MetaZeus himself rewards those who bring value to his kingdom with incredible bounties…</p>
      </div> 
    </div>
  </section>
  <section> 
  <div className="row align-items-center pt-5">
      <div className="col-md-6 mb-md-0 mb-4">
      <h4 className='meta pb-4'>Skillz</h4>

        <p className='pe-lg-5'>Whether you're a coding whiz, UX mastermind or social media guru,
           you’ve spent years mastering your craft. <br /><br /> And MetaZeus recognizes your skillset,
            oh yes… <br /><br /> To rise to the vaulted ceilings of his MetaOlympus, you’ll need to bring your
             A-game every day. <br /><br /> And just as you’re willing to share your skillz with each and every
              one of your fellow anons, they are ready and waiting to help you level up yours…</p>
      </div> 
      <div className="col-md-5 offset-md-1 col-10 mx-auto">
        <img src="assets/images/v3.png" className="w-100"  alt="" />
        </div>
    </div>
  </section>
  <section>
  <div className="row align-items-center pt-5">
      <div className="col-md-5 order-md-0 order-1 col-10 mx-auto">
        <img src="assets/images/v4.png" className="w-100"  alt="" />

        </div>
      <div className="col-md-6 offset-md-1 mb-md-0 mb-4">
      <h4 className='meta pb-4'>Chuckles</h4>

        <p className='pe-lg-5'>Yes, you read that right… Chuckles. Even the God of Lightning himself enjoys nothing 
          more than a jolly old time. <br /><br /> We’re here to have fun – don’t you doubt it. If you're
           not enjoying what you're doing, you're doing it wrong – that’s what we believe. <br /><br />
           So bring your sense of humor along for the ride, and join in building web3’s most
            engaging and entertaining ecosystem yet. <br /><br /> By MetaZeus himself, the results shall be most radical…</p>
      </div> 
    </div>
  </section>
  </ReactPageScroller>
      </div>


      <div className="d-block d-md-none">
      
    <div className="row align-items-center fade-in">
      <div className="col-md-12">
      <h2 className='meta pt-5 pt-md-0 mt-5 mt-md-0'>Vision</h2>

      </div>
      <div className="col-md-6 mb-md-0 mb-4">
         <h4 className='meta pb-4'>Who is metazeus?</h4>
       <p className='pe-lg-5'>
       A personification of web3 culture, MetaZeus represents each and every degen out there riding narratives,
        seeking alpha and unearthing shifts in the meta. <br /> <br /> And just as he led the gods of Greek mythology,
         Zeus stands as the electrifying meta-ruler of our digital realm – a digital hero with extraordinary
          abilities… <br /><br /> In his honor, we unite a vibrant community of web3 experimenters who together shall
           carve out the mythic movement of web3. Each of our mesmerizing Metas features a momentary snapshot
            of web3 culture, complete with its own rabbit hole. <br /><br /> The only constant is change… Can you keep up, anon?
        </p>
       
      </div>
      <div className="col-md-5 offset-md-1 col-10 mx-auto">
        <img src="assets/images/v1.png" className="w-100"  alt="" />
        </div>
    </div>
    <div className="row align-items-center pt-5 fade-in">
      <div className="col-md-5 order-md-0 order-1 col-10 mx-auto">
        <img src="assets/images/v2.png" className="w-100"  alt="" />

        </div>
      <div className="col-md-6 offset-md-1 mb-md-0 mb-4">
      <h4 className='meta pb-4'>Community</h4>

        <p className='pe-lg-5'>To succeed in anything, you need people who believe – especially in web3.
           But not just any old people… <br /><br /> You need a tribe of like-minded, passionate folks who feel the energy of your vision,
            share in your common goal – and are deeply inspired to contribute to ongoing growth. <br /><br />
          Let it be known, MetaZeus himself rewards those who bring value to his kingdom with incredible bounties…</p>
      </div> 
    </div>
  <div className="row align-items-center pt-5">
      <div className="col-md-6 mb-md-0 mb-4">
      <h4 className='meta pb-4'>Skillz</h4>

        <p className='pe-lg-5'>Whether you're a coding whiz, UX mastermind or social media guru,
           you’ve spent years mastering your craft. <br /><br /> And MetaZeus recognizes your skillset,
            oh yes… <br /><br /> To rise to the vaulted ceilings of his MetaOlympus, you’ll need to bring your
             A-game every day. <br /><br /> And just as you’re willing to share your skillz with each and every
              one of your fellow anons, they are ready and waiting to help you level up yours…</p>
      </div> 
      <div className="col-md-5 offset-md-1 col-10 mx-auto">
        <img src="assets/images/v3.png" className="w-100"  alt="" />
        </div>
    </div>
  <div className="row align-items-center py-5">
      <div className="col-md-5 order-md-0 order-1 col-10 mx-auto">
        <img src="assets/images/v4.png" className="w-100"  alt="" />

        </div>
      <div className="col-md-6 offset-md-1 mb-md-0 mb-4">
      <h4 className='meta pb-4'>Chuckles</h4>

        <p className='pe-lg-5'>Yes, you read that right… Chuckles. Even the God of Lightning himself enjoys nothing 
          more than a jolly old time. <br /><br /> We’re here to have fun – don’t you doubt it. If you're
           not enjoying what you're doing, you're doing it wrong – that’s what we believe. <br /><br />
           So bring your sense of humor along for the ride, and join in building web3’s most
            engaging and entertaining ecosystem yet. <br /><br /> By MetaZeus himself, the results shall be most radical…</p>
      </div> 
    </div>
      </div>



</div>}

    </>
  )
}

export default Vision